@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Nunito", sans-serif;
}

.particle-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 10;
}

.particle {
  position: absolute;
  bottom: -10%; /* Start below the screen to ensure particles are not visible initially */
  animation-name: floatUp;
}

.stableScrollGutters {
  scrollbar-width: none;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
}

.animate-pulse {
  animation: pulse 2s infinite ease-in-out;
}

/* Tailwind CSS */
@keyframes pulse2 {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse2 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes floatUp {
  to {
    transform: translateY(-120vh); /* Move to above the top of the screen */
  }
}

@layer components {
  .feature-section {
    @apply p-4 lg:p-8 shadow rounded-lg flex flex-col lg:text-lg;
  }
  .sf-button {
    @apply p-2 px-4 shadow rounded-md bg-sf-slate text-sf-text hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75;
  }
}

.snuggle-tag-stage {
  background-color: #2e7d32; /* Darker green for stages */
}
.snuggle-tag-ai {
  background-color: #ff8f00; /* Darker amber for AI-related tags */
}
.snuggle-tag-age {
  background-color: #0d47a1; /* Darker blue for age restrictions */
}
.snuggle-tag-cost {
  background-color: #c62828; /* Darker red for cost-related tags */
}

.bg-forest-overlay {
  position: relative;
  z-index: 0;
}

.bg-forest-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../res/images/forest-bg.webp?as=webp&width=800&quality=75");
  background-repeat: repeat;
  opacity: 0.2; /* Adjust the opacity as needed */
  z-index: -1;
}
