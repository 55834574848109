@import "https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap";
*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.feature-section {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  flex-direction: column;
  padding: 1rem;
  display: flex;
}

@media (width >= 1024px) {
  .feature-section {
    padding: 2rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.m-2 {
  margin: .5rem;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.h-10 {
  height: 2.5rem;
}

.h-16 {
  height: 4rem;
}

.h-24 {
  height: 6rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-18vh {
  min-height: 18vh;
}

.min-h-32 {
  min-height: 8rem;
}

.min-h-full {
  min-height: 100%;
}

.w-10 {
  width: 2.5rem;
}

.w-16 {
  width: 4rem;
}

.w-24 {
  width: 6rem;
}

.w-56 {
  width: 14rem;
}

.w-8 {
  width: 2rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.min-w-8 {
  min-width: 2rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-\[64rem\] {
  max-width: 64rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes delayedFadeOut {
  0%, 50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.animate-delayedFadeOut {
  animation: 2s ease-out forwards delayedFadeOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: .5s ease-in forwards fadeIn;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: .7;
    transform: scale(1.2);
  }
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.resize-none {
  resize: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-\[1fr_auto\] {
  grid-template-columns: 1fr auto;
}

.grid-cols-\[auto_1fr\] {
  grid-template-columns: auto 1fr;
}

.grid-rows-\[auto_1fr_auto\] {
  grid-template-rows: auto 1fr auto;
}

.grid-rows-\[auto_auto_1fr_auto\] {
  grid-template-rows: auto auto 1fr auto;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-8 {
  gap: 2rem;
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity, 1));
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.text-wrap {
  text-wrap: wrap;
}

.text-nowrap {
  text-wrap: nowrap;
}

.text-pretty {
  text-wrap: pretty;
}

.rounded {
  border-radius: .25rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.border {
  border-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-none {
  border-style: none;
}

.border-glow-glowLightLavender {
  --tw-border-opacity: 1;
  border-color: rgb(232 224 255 / var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity, 1));
}

.border-transparent {
  border-color: #0000;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}

.bg-black\/25 {
  background-color: #00000040;
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity, 1));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity, 1));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity, 1));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

.bg-sf-background {
  --tw-bg-opacity: 1;
  background-color: rgb(232 245 233 / var(--tw-bg-opacity, 1));
}

.bg-sf-background-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(38 50 56 / var(--tw-bg-opacity, 1));
}

.bg-sf-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(56 142 60 / var(--tw-bg-opacity, 1));
}

.bg-sf-slate {
  --tw-bg-opacity: 1;
  background-color: rgb(47 79 79 / var(--tw-bg-opacity, 1));
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity, 1));
}

.bg-surface-bush3 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 169 130 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower1 {
  --tw-bg-opacity: 1;
  background-color: rgb(230 115 0 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower1\/0 {
  background-color: #e6730000;
}

.bg-surface-flower1\/10 {
  background-color: #e673001a;
}

.bg-surface-flower1\/100 {
  background-color: #e67300;
}

.bg-surface-flower1\/15 {
  background-color: #e6730026;
}

.bg-surface-flower1\/20 {
  background-color: #e6730033;
}

.bg-surface-flower1\/25 {
  background-color: #e6730040;
}

.bg-surface-flower1\/30 {
  background-color: #e673004d;
}

.bg-surface-flower1\/35 {
  background-color: #e6730059;
}

.bg-surface-flower1\/40 {
  background-color: #e6730066;
}

.bg-surface-flower1\/45 {
  background-color: #e6730073;
}

.bg-surface-flower1\/5 {
  background-color: #e673000d;
}

.bg-surface-flower1\/50 {
  background-color: #e6730080;
}

.bg-surface-flower1\/55 {
  background-color: #e673008c;
}

.bg-surface-flower1\/60 {
  background-color: #e6730099;
}

.bg-surface-flower1\/65 {
  background-color: #e67300a6;
}

.bg-surface-flower1\/70 {
  background-color: #e67300b3;
}

.bg-surface-flower1\/75 {
  background-color: #e67300bf;
}

.bg-surface-flower1\/80 {
  background-color: #e67300cc;
}

.bg-surface-flower1\/85 {
  background-color: #e67300d9;
}

.bg-surface-flower1\/90 {
  background-color: #e67300e6;
}

.bg-surface-flower1\/95 {
  background-color: #e67300f2;
}

.bg-surface-flower1Active {
  --tw-bg-opacity: 1;
  background-color: rgb(179 89 0 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower1Active\/0 {
  background-color: #b3590000;
}

.bg-surface-flower1Active\/10 {
  background-color: #b359001a;
}

.bg-surface-flower1Active\/100 {
  background-color: #b35900;
}

.bg-surface-flower1Active\/15 {
  background-color: #b3590026;
}

.bg-surface-flower1Active\/20 {
  background-color: #b3590033;
}

.bg-surface-flower1Active\/25 {
  background-color: #b3590040;
}

.bg-surface-flower1Active\/30 {
  background-color: #b359004d;
}

.bg-surface-flower1Active\/35 {
  background-color: #b3590059;
}

.bg-surface-flower1Active\/40 {
  background-color: #b3590066;
}

.bg-surface-flower1Active\/45 {
  background-color: #b3590073;
}

.bg-surface-flower1Active\/5 {
  background-color: #b359000d;
}

.bg-surface-flower1Active\/50 {
  background-color: #b3590080;
}

.bg-surface-flower1Active\/55 {
  background-color: #b359008c;
}

.bg-surface-flower1Active\/60 {
  background-color: #b3590099;
}

.bg-surface-flower1Active\/65 {
  background-color: #b35900a6;
}

.bg-surface-flower1Active\/70 {
  background-color: #b35900b3;
}

.bg-surface-flower1Active\/75 {
  background-color: #b35900bf;
}

.bg-surface-flower1Active\/80 {
  background-color: #b35900cc;
}

.bg-surface-flower1Active\/85 {
  background-color: #b35900d9;
}

.bg-surface-flower1Active\/90 {
  background-color: #b35900e6;
}

.bg-surface-flower1Active\/95 {
  background-color: #b35900f2;
}

.bg-surface-flower1Hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 140 0 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower1Hover\/0 {
  background-color: #ff8c0000;
}

.bg-surface-flower1Hover\/10 {
  background-color: #ff8c001a;
}

.bg-surface-flower1Hover\/100 {
  background-color: #ff8c00;
}

.bg-surface-flower1Hover\/15 {
  background-color: #ff8c0026;
}

.bg-surface-flower1Hover\/20 {
  background-color: #ff8c0033;
}

.bg-surface-flower1Hover\/25 {
  background-color: #ff8c0040;
}

.bg-surface-flower1Hover\/30 {
  background-color: #ff8c004d;
}

.bg-surface-flower1Hover\/35 {
  background-color: #ff8c0059;
}

.bg-surface-flower1Hover\/40 {
  background-color: #ff8c0066;
}

.bg-surface-flower1Hover\/45 {
  background-color: #ff8c0073;
}

.bg-surface-flower1Hover\/5 {
  background-color: #ff8c000d;
}

.bg-surface-flower1Hover\/50 {
  background-color: #ff8c0080;
}

.bg-surface-flower1Hover\/55 {
  background-color: #ff8c008c;
}

.bg-surface-flower1Hover\/60 {
  background-color: #ff8c0099;
}

.bg-surface-flower1Hover\/65 {
  background-color: #ff8c00a6;
}

.bg-surface-flower1Hover\/70 {
  background-color: #ff8c00b3;
}

.bg-surface-flower1Hover\/75 {
  background-color: #ff8c00bf;
}

.bg-surface-flower1Hover\/80 {
  background-color: #ff8c00cc;
}

.bg-surface-flower1Hover\/85 {
  background-color: #ff8c00d9;
}

.bg-surface-flower1Hover\/90 {
  background-color: #ff8c00e6;
}

.bg-surface-flower1Hover\/95 {
  background-color: #ff8c00f2;
}

.bg-surface-flower2 {
  --tw-bg-opacity: 1;
  background-color: rgb(204 51 0 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower2\/0 {
  background-color: #c300;
}

.bg-surface-flower2\/10 {
  background-color: #cc33001a;
}

.bg-surface-flower2\/100 {
  background-color: #c30;
}

.bg-surface-flower2\/15 {
  background-color: #cc330026;
}

.bg-surface-flower2\/20 {
  background-color: #c303;
}

.bg-surface-flower2\/25 {
  background-color: #cc330040;
}

.bg-surface-flower2\/30 {
  background-color: #cc33004d;
}

.bg-surface-flower2\/35 {
  background-color: #cc330059;
}

.bg-surface-flower2\/40 {
  background-color: #c306;
}

.bg-surface-flower2\/45 {
  background-color: #cc330073;
}

.bg-surface-flower2\/5 {
  background-color: #cc33000d;
}

.bg-surface-flower2\/50 {
  background-color: #cc330080;
}

.bg-surface-flower2\/55 {
  background-color: #cc33008c;
}

.bg-surface-flower2\/60 {
  background-color: #c309;
}

.bg-surface-flower2\/65 {
  background-color: #cc3300a6;
}

.bg-surface-flower2\/70 {
  background-color: #cc3300b3;
}

.bg-surface-flower2\/75 {
  background-color: #cc3300bf;
}

.bg-surface-flower2\/80 {
  background-color: #c30c;
}

.bg-surface-flower2\/85 {
  background-color: #cc3300d9;
}

.bg-surface-flower2\/90 {
  background-color: #cc3300e6;
}

.bg-surface-flower2\/95 {
  background-color: #cc3300f2;
}

.bg-surface-flower2Active {
  --tw-bg-opacity: 1;
  background-color: rgb(178 34 34 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower2Active\/0 {
  background-color: #b2222200;
}

.bg-surface-flower2Active\/10 {
  background-color: #b222221a;
}

.bg-surface-flower2Active\/100 {
  background-color: #b22222;
}

.bg-surface-flower2Active\/15 {
  background-color: #b2222226;
}

.bg-surface-flower2Active\/20 {
  background-color: #b2222233;
}

.bg-surface-flower2Active\/25 {
  background-color: #b2222240;
}

.bg-surface-flower2Active\/30 {
  background-color: #b222224d;
}

.bg-surface-flower2Active\/35 {
  background-color: #b2222259;
}

.bg-surface-flower2Active\/40 {
  background-color: #b2222266;
}

.bg-surface-flower2Active\/45 {
  background-color: #b2222273;
}

.bg-surface-flower2Active\/5 {
  background-color: #b222220d;
}

.bg-surface-flower2Active\/50 {
  background-color: #b2222280;
}

.bg-surface-flower2Active\/55 {
  background-color: #b222228c;
}

.bg-surface-flower2Active\/60 {
  background-color: #b2222299;
}

.bg-surface-flower2Active\/65 {
  background-color: #b22222a6;
}

.bg-surface-flower2Active\/70 {
  background-color: #b22222b3;
}

.bg-surface-flower2Active\/75 {
  background-color: #b22222bf;
}

.bg-surface-flower2Active\/80 {
  background-color: #b22222cc;
}

.bg-surface-flower2Active\/85 {
  background-color: #b22222d9;
}

.bg-surface-flower2Active\/90 {
  background-color: #b22222e6;
}

.bg-surface-flower2Active\/95 {
  background-color: #b22222f2;
}

.bg-surface-flower2Hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 69 0 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower2Hover\/0 {
  background-color: #ff450000;
}

.bg-surface-flower2Hover\/10 {
  background-color: #ff45001a;
}

.bg-surface-flower2Hover\/100 {
  background-color: #ff4500;
}

.bg-surface-flower2Hover\/15 {
  background-color: #ff450026;
}

.bg-surface-flower2Hover\/20 {
  background-color: #ff450033;
}

.bg-surface-flower2Hover\/25 {
  background-color: #ff450040;
}

.bg-surface-flower2Hover\/30 {
  background-color: #ff45004d;
}

.bg-surface-flower2Hover\/35 {
  background-color: #ff450059;
}

.bg-surface-flower2Hover\/40 {
  background-color: #ff450066;
}

.bg-surface-flower2Hover\/45 {
  background-color: #ff450073;
}

.bg-surface-flower2Hover\/5 {
  background-color: #ff45000d;
}

.bg-surface-flower2Hover\/50 {
  background-color: #ff450080;
}

.bg-surface-flower2Hover\/55 {
  background-color: #ff45008c;
}

.bg-surface-flower2Hover\/60 {
  background-color: #ff450099;
}

.bg-surface-flower2Hover\/65 {
  background-color: #ff4500a6;
}

.bg-surface-flower2Hover\/70 {
  background-color: #ff4500b3;
}

.bg-surface-flower2Hover\/75 {
  background-color: #ff4500bf;
}

.bg-surface-flower2Hover\/80 {
  background-color: #ff4500cc;
}

.bg-surface-flower2Hover\/85 {
  background-color: #ff4500d9;
}

.bg-surface-flower2Hover\/90 {
  background-color: #ff4500e6;
}

.bg-surface-flower2Hover\/95 {
  background-color: #ff4500f2;
}

.bg-surface-flower3 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 139 34 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower3\/0 {
  background-color: #228b2200;
}

.bg-surface-flower3\/10 {
  background-color: #228b221a;
}

.bg-surface-flower3\/100 {
  background-color: #228b22;
}

.bg-surface-flower3\/15 {
  background-color: #228b2226;
}

.bg-surface-flower3\/20 {
  background-color: #228b2233;
}

.bg-surface-flower3\/25 {
  background-color: #228b2240;
}

.bg-surface-flower3\/30 {
  background-color: #228b224d;
}

.bg-surface-flower3\/35 {
  background-color: #228b2259;
}

.bg-surface-flower3\/40 {
  background-color: #228b2266;
}

.bg-surface-flower3\/45 {
  background-color: #228b2273;
}

.bg-surface-flower3\/5 {
  background-color: #228b220d;
}

.bg-surface-flower3\/50 {
  background-color: #228b2280;
}

.bg-surface-flower3\/55 {
  background-color: #228b228c;
}

.bg-surface-flower3\/60 {
  background-color: #228b2299;
}

.bg-surface-flower3\/65 {
  background-color: #228b22a6;
}

.bg-surface-flower3\/70 {
  background-color: #228b22b3;
}

.bg-surface-flower3\/75 {
  background-color: #228b22bf;
}

.bg-surface-flower3\/80 {
  background-color: #228b22cc;
}

.bg-surface-flower3\/85 {
  background-color: #228b22d9;
}

.bg-surface-flower3\/90 {
  background-color: #228b22e6;
}

.bg-surface-flower3\/95 {
  background-color: #228b22f2;
}

.bg-surface-flower3Active {
  --tw-bg-opacity: 1;
  background-color: rgb(0 77 0 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower3Active\/0 {
  background-color: #004d0000;
}

.bg-surface-flower3Active\/10 {
  background-color: #004d001a;
}

.bg-surface-flower3Active\/100 {
  background-color: #004d00;
}

.bg-surface-flower3Active\/15 {
  background-color: #004d0026;
}

.bg-surface-flower3Active\/20 {
  background-color: #004d0033;
}

.bg-surface-flower3Active\/25 {
  background-color: #004d0040;
}

.bg-surface-flower3Active\/30 {
  background-color: #004d004d;
}

.bg-surface-flower3Active\/35 {
  background-color: #004d0059;
}

.bg-surface-flower3Active\/40 {
  background-color: #004d0066;
}

.bg-surface-flower3Active\/45 {
  background-color: #004d0073;
}

.bg-surface-flower3Active\/5 {
  background-color: #004d000d;
}

.bg-surface-flower3Active\/50 {
  background-color: #004d0080;
}

.bg-surface-flower3Active\/55 {
  background-color: #004d008c;
}

.bg-surface-flower3Active\/60 {
  background-color: #004d0099;
}

.bg-surface-flower3Active\/65 {
  background-color: #004d00a6;
}

.bg-surface-flower3Active\/70 {
  background-color: #004d00b3;
}

.bg-surface-flower3Active\/75 {
  background-color: #004d00bf;
}

.bg-surface-flower3Active\/80 {
  background-color: #004d00cc;
}

.bg-surface-flower3Active\/85 {
  background-color: #004d00d9;
}

.bg-surface-flower3Active\/90 {
  background-color: #004d00e6;
}

.bg-surface-flower3Active\/95 {
  background-color: #004d00f2;
}

.bg-surface-flower3Hover {
  --tw-bg-opacity: 1;
  background-color: rgb(50 153 51 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower3Hover\/0 {
  background-color: #32993300;
}

.bg-surface-flower3Hover\/10 {
  background-color: #3299331a;
}

.bg-surface-flower3Hover\/100 {
  background-color: #329933;
}

.bg-surface-flower3Hover\/15 {
  background-color: #32993326;
}

.bg-surface-flower3Hover\/20 {
  background-color: #32993333;
}

.bg-surface-flower3Hover\/25 {
  background-color: #32993340;
}

.bg-surface-flower3Hover\/30 {
  background-color: #3299334d;
}

.bg-surface-flower3Hover\/35 {
  background-color: #32993359;
}

.bg-surface-flower3Hover\/40 {
  background-color: #32993366;
}

.bg-surface-flower3Hover\/45 {
  background-color: #32993373;
}

.bg-surface-flower3Hover\/5 {
  background-color: #3299330d;
}

.bg-surface-flower3Hover\/50 {
  background-color: #32993380;
}

.bg-surface-flower3Hover\/55 {
  background-color: #3299338c;
}

.bg-surface-flower3Hover\/60 {
  background-color: #32993399;
}

.bg-surface-flower3Hover\/65 {
  background-color: #329933a6;
}

.bg-surface-flower3Hover\/70 {
  background-color: #329933b3;
}

.bg-surface-flower3Hover\/75 {
  background-color: #329933bf;
}

.bg-surface-flower3Hover\/80 {
  background-color: #329933cc;
}

.bg-surface-flower3Hover\/85 {
  background-color: #329933d9;
}

.bg-surface-flower3Hover\/90 {
  background-color: #329933e6;
}

.bg-surface-flower3Hover\/95 {
  background-color: #329933f2;
}

.bg-surface-flower4 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 99 71 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower4\/0 {
  background-color: #ff634700;
}

.bg-surface-flower4\/10 {
  background-color: #ff63471a;
}

.bg-surface-flower4\/100 {
  background-color: tomato;
}

.bg-surface-flower4\/15 {
  background-color: #ff634726;
}

.bg-surface-flower4\/20 {
  background-color: #ff634733;
}

.bg-surface-flower4\/25 {
  background-color: #ff634740;
}

.bg-surface-flower4\/30 {
  background-color: #ff63474d;
}

.bg-surface-flower4\/35 {
  background-color: #ff634759;
}

.bg-surface-flower4\/40 {
  background-color: #ff634766;
}

.bg-surface-flower4\/45 {
  background-color: #ff634773;
}

.bg-surface-flower4\/5 {
  background-color: #ff63470d;
}

.bg-surface-flower4\/50 {
  background-color: #ff634780;
}

.bg-surface-flower4\/55 {
  background-color: #ff63478c;
}

.bg-surface-flower4\/60 {
  background-color: #ff634799;
}

.bg-surface-flower4\/65 {
  background-color: #ff6347a6;
}

.bg-surface-flower4\/70 {
  background-color: #ff6347b3;
}

.bg-surface-flower4\/75 {
  background-color: #ff6347bf;
}

.bg-surface-flower4\/80 {
  background-color: #ff6347cc;
}

.bg-surface-flower4\/85 {
  background-color: #ff6347d9;
}

.bg-surface-flower4\/90 {
  background-color: #ff6347e6;
}

.bg-surface-flower4\/95 {
  background-color: #ff6347f2;
}

.bg-surface-flower4Active {
  --tw-bg-opacity: 1;
  background-color: rgb(205 92 92 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower4Active\/0 {
  background-color: #cd5c5c00;
}

.bg-surface-flower4Active\/10 {
  background-color: #cd5c5c1a;
}

.bg-surface-flower4Active\/100 {
  background-color: #cd5c5c;
}

.bg-surface-flower4Active\/15 {
  background-color: #cd5c5c26;
}

.bg-surface-flower4Active\/20 {
  background-color: #cd5c5c33;
}

.bg-surface-flower4Active\/25 {
  background-color: #cd5c5c40;
}

.bg-surface-flower4Active\/30 {
  background-color: #cd5c5c4d;
}

.bg-surface-flower4Active\/35 {
  background-color: #cd5c5c59;
}

.bg-surface-flower4Active\/40 {
  background-color: #cd5c5c66;
}

.bg-surface-flower4Active\/45 {
  background-color: #cd5c5c73;
}

.bg-surface-flower4Active\/5 {
  background-color: #cd5c5c0d;
}

.bg-surface-flower4Active\/50 {
  background-color: #cd5c5c80;
}

.bg-surface-flower4Active\/55 {
  background-color: #cd5c5c8c;
}

.bg-surface-flower4Active\/60 {
  background-color: #cd5c5c99;
}

.bg-surface-flower4Active\/65 {
  background-color: #cd5c5ca6;
}

.bg-surface-flower4Active\/70 {
  background-color: #cd5c5cb3;
}

.bg-surface-flower4Active\/75 {
  background-color: #cd5c5cbf;
}

.bg-surface-flower4Active\/80 {
  background-color: #cd5c5ccc;
}

.bg-surface-flower4Active\/85 {
  background-color: #cd5c5cd9;
}

.bg-surface-flower4Active\/90 {
  background-color: #cd5c5ce6;
}

.bg-surface-flower4Active\/95 {
  background-color: #cd5c5cf2;
}

.bg-surface-flower4Hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 127 80 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower4Hover\/0 {
  background-color: #ff7f5000;
}

.bg-surface-flower4Hover\/10 {
  background-color: #ff7f501a;
}

.bg-surface-flower4Hover\/100 {
  background-color: coral;
}

.bg-surface-flower4Hover\/15 {
  background-color: #ff7f5026;
}

.bg-surface-flower4Hover\/20 {
  background-color: #ff7f5033;
}

.bg-surface-flower4Hover\/25 {
  background-color: #ff7f5040;
}

.bg-surface-flower4Hover\/30 {
  background-color: #ff7f504d;
}

.bg-surface-flower4Hover\/35 {
  background-color: #ff7f5059;
}

.bg-surface-flower4Hover\/40 {
  background-color: #ff7f5066;
}

.bg-surface-flower4Hover\/45 {
  background-color: #ff7f5073;
}

.bg-surface-flower4Hover\/5 {
  background-color: #ff7f500d;
}

.bg-surface-flower4Hover\/50 {
  background-color: #ff7f5080;
}

.bg-surface-flower4Hover\/55 {
  background-color: #ff7f508c;
}

.bg-surface-flower4Hover\/60 {
  background-color: #ff7f5099;
}

.bg-surface-flower4Hover\/65 {
  background-color: #ff7f50a6;
}

.bg-surface-flower4Hover\/70 {
  background-color: #ff7f50b3;
}

.bg-surface-flower4Hover\/75 {
  background-color: #ff7f50bf;
}

.bg-surface-flower4Hover\/80 {
  background-color: #ff7f50cc;
}

.bg-surface-flower4Hover\/85 {
  background-color: #ff7f50d9;
}

.bg-surface-flower4Hover\/90 {
  background-color: #ff7f50e6;
}

.bg-surface-flower4Hover\/95 {
  background-color: #ff7f50f2;
}

.bg-surface-flower5 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 215 0 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower5\/0 {
  background-color: #ffd70000;
}

.bg-surface-flower5\/10 {
  background-color: #ffd7001a;
}

.bg-surface-flower5\/100 {
  background-color: gold;
}

.bg-surface-flower5\/15 {
  background-color: #ffd70026;
}

.bg-surface-flower5\/20 {
  background-color: #ffd70033;
}

.bg-surface-flower5\/25 {
  background-color: #ffd70040;
}

.bg-surface-flower5\/30 {
  background-color: #ffd7004d;
}

.bg-surface-flower5\/35 {
  background-color: #ffd70059;
}

.bg-surface-flower5\/40 {
  background-color: #ffd70066;
}

.bg-surface-flower5\/45 {
  background-color: #ffd70073;
}

.bg-surface-flower5\/5 {
  background-color: #ffd7000d;
}

.bg-surface-flower5\/50 {
  background-color: #ffd70080;
}

.bg-surface-flower5\/55 {
  background-color: #ffd7008c;
}

.bg-surface-flower5\/60 {
  background-color: #ffd70099;
}

.bg-surface-flower5\/65 {
  background-color: #ffd700a6;
}

.bg-surface-flower5\/70 {
  background-color: #ffd700b3;
}

.bg-surface-flower5\/75 {
  background-color: #ffd700bf;
}

.bg-surface-flower5\/80 {
  background-color: #ffd700cc;
}

.bg-surface-flower5\/85 {
  background-color: #ffd700d9;
}

.bg-surface-flower5\/90 {
  background-color: #ffd700e6;
}

.bg-surface-flower5\/95 {
  background-color: #ffd700f2;
}

.bg-surface-flower5Active {
  --tw-bg-opacity: 1;
  background-color: rgb(255 160 122 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower5Active\/0 {
  background-color: #ffa07a00;
}

.bg-surface-flower5Active\/10 {
  background-color: #ffa07a1a;
}

.bg-surface-flower5Active\/100 {
  background-color: #ffa07a;
}

.bg-surface-flower5Active\/15 {
  background-color: #ffa07a26;
}

.bg-surface-flower5Active\/20 {
  background-color: #ffa07a33;
}

.bg-surface-flower5Active\/25 {
  background-color: #ffa07a40;
}

.bg-surface-flower5Active\/30 {
  background-color: #ffa07a4d;
}

.bg-surface-flower5Active\/35 {
  background-color: #ffa07a59;
}

.bg-surface-flower5Active\/40 {
  background-color: #ffa07a66;
}

.bg-surface-flower5Active\/45 {
  background-color: #ffa07a73;
}

.bg-surface-flower5Active\/5 {
  background-color: #ffa07a0d;
}

.bg-surface-flower5Active\/50 {
  background-color: #ffa07a80;
}

.bg-surface-flower5Active\/55 {
  background-color: #ffa07a8c;
}

.bg-surface-flower5Active\/60 {
  background-color: #ffa07a99;
}

.bg-surface-flower5Active\/65 {
  background-color: #ffa07aa6;
}

.bg-surface-flower5Active\/70 {
  background-color: #ffa07ab3;
}

.bg-surface-flower5Active\/75 {
  background-color: #ffa07abf;
}

.bg-surface-flower5Active\/80 {
  background-color: #ffa07acc;
}

.bg-surface-flower5Active\/85 {
  background-color: #ffa07ad9;
}

.bg-surface-flower5Active\/90 {
  background-color: #ffa07ae6;
}

.bg-surface-flower5Active\/95 {
  background-color: #ffa07af2;
}

.bg-surface-flower5Hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 165 0 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower5Hover\/0 {
  background-color: #ffa50000;
}

.bg-surface-flower5Hover\/10 {
  background-color: #ffa5001a;
}

.bg-surface-flower5Hover\/100 {
  background-color: orange;
}

.bg-surface-flower5Hover\/15 {
  background-color: #ffa50026;
}

.bg-surface-flower5Hover\/20 {
  background-color: #ffa50033;
}

.bg-surface-flower5Hover\/25 {
  background-color: #ffa50040;
}

.bg-surface-flower5Hover\/30 {
  background-color: #ffa5004d;
}

.bg-surface-flower5Hover\/35 {
  background-color: #ffa50059;
}

.bg-surface-flower5Hover\/40 {
  background-color: #ffa50066;
}

.bg-surface-flower5Hover\/45 {
  background-color: #ffa50073;
}

.bg-surface-flower5Hover\/5 {
  background-color: #ffa5000d;
}

.bg-surface-flower5Hover\/50 {
  background-color: #ffa50080;
}

.bg-surface-flower5Hover\/55 {
  background-color: #ffa5008c;
}

.bg-surface-flower5Hover\/60 {
  background-color: #ffa50099;
}

.bg-surface-flower5Hover\/65 {
  background-color: #ffa500a6;
}

.bg-surface-flower5Hover\/70 {
  background-color: #ffa500b3;
}

.bg-surface-flower5Hover\/75 {
  background-color: #ffa500bf;
}

.bg-surface-flower5Hover\/80 {
  background-color: #ffa500cc;
}

.bg-surface-flower5Hover\/85 {
  background-color: #ffa500d9;
}

.bg-surface-flower5Hover\/90 {
  background-color: #ffa500e6;
}

.bg-surface-flower5Hover\/95 {
  background-color: #ffa500f2;
}

.bg-surface-flower6 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 105 180 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower6\/0 {
  background-color: #ff69b400;
}

.bg-surface-flower6\/10 {
  background-color: #ff69b41a;
}

.bg-surface-flower6\/100 {
  background-color: #ff69b4;
}

.bg-surface-flower6\/15 {
  background-color: #ff69b426;
}

.bg-surface-flower6\/20 {
  background-color: #ff69b433;
}

.bg-surface-flower6\/25 {
  background-color: #ff69b440;
}

.bg-surface-flower6\/30 {
  background-color: #ff69b44d;
}

.bg-surface-flower6\/35 {
  background-color: #ff69b459;
}

.bg-surface-flower6\/40 {
  background-color: #ff69b466;
}

.bg-surface-flower6\/45 {
  background-color: #ff69b473;
}

.bg-surface-flower6\/5 {
  background-color: #ff69b40d;
}

.bg-surface-flower6\/50 {
  background-color: #ff69b480;
}

.bg-surface-flower6\/55 {
  background-color: #ff69b48c;
}

.bg-surface-flower6\/60 {
  background-color: #ff69b499;
}

.bg-surface-flower6\/65 {
  background-color: #ff69b4a6;
}

.bg-surface-flower6\/70 {
  background-color: #ff69b4b3;
}

.bg-surface-flower6\/75 {
  background-color: #ff69b4bf;
}

.bg-surface-flower6\/80 {
  background-color: #ff69b4cc;
}

.bg-surface-flower6\/85 {
  background-color: #ff69b4d9;
}

.bg-surface-flower6\/90 {
  background-color: #ff69b4e6;
}

.bg-surface-flower6\/95 {
  background-color: #ff69b4f2;
}

.bg-surface-flower6Active {
  --tw-bg-opacity: 1;
  background-color: rgb(199 21 133 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower6Active\/0 {
  background-color: #c7158500;
}

.bg-surface-flower6Active\/10 {
  background-color: #c715851a;
}

.bg-surface-flower6Active\/100 {
  background-color: #c71585;
}

.bg-surface-flower6Active\/15 {
  background-color: #c7158526;
}

.bg-surface-flower6Active\/20 {
  background-color: #c7158533;
}

.bg-surface-flower6Active\/25 {
  background-color: #c7158540;
}

.bg-surface-flower6Active\/30 {
  background-color: #c715854d;
}

.bg-surface-flower6Active\/35 {
  background-color: #c7158559;
}

.bg-surface-flower6Active\/40 {
  background-color: #c7158566;
}

.bg-surface-flower6Active\/45 {
  background-color: #c7158573;
}

.bg-surface-flower6Active\/5 {
  background-color: #c715850d;
}

.bg-surface-flower6Active\/50 {
  background-color: #c7158580;
}

.bg-surface-flower6Active\/55 {
  background-color: #c715858c;
}

.bg-surface-flower6Active\/60 {
  background-color: #c7158599;
}

.bg-surface-flower6Active\/65 {
  background-color: #c71585a6;
}

.bg-surface-flower6Active\/70 {
  background-color: #c71585b3;
}

.bg-surface-flower6Active\/75 {
  background-color: #c71585bf;
}

.bg-surface-flower6Active\/80 {
  background-color: #c71585cc;
}

.bg-surface-flower6Active\/85 {
  background-color: #c71585d9;
}

.bg-surface-flower6Active\/90 {
  background-color: #c71585e6;
}

.bg-surface-flower6Active\/95 {
  background-color: #c71585f2;
}

.bg-surface-flower6Hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 20 147 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower6Hover\/0 {
  background-color: #ff149300;
}

.bg-surface-flower6Hover\/10 {
  background-color: #ff14931a;
}

.bg-surface-flower6Hover\/100 {
  background-color: #ff1493;
}

.bg-surface-flower6Hover\/15 {
  background-color: #ff149326;
}

.bg-surface-flower6Hover\/20 {
  background-color: #ff149333;
}

.bg-surface-flower6Hover\/25 {
  background-color: #ff149340;
}

.bg-surface-flower6Hover\/30 {
  background-color: #ff14934d;
}

.bg-surface-flower6Hover\/35 {
  background-color: #ff149359;
}

.bg-surface-flower6Hover\/40 {
  background-color: #ff149366;
}

.bg-surface-flower6Hover\/45 {
  background-color: #ff149373;
}

.bg-surface-flower6Hover\/5 {
  background-color: #ff14930d;
}

.bg-surface-flower6Hover\/50 {
  background-color: #ff149380;
}

.bg-surface-flower6Hover\/55 {
  background-color: #ff14938c;
}

.bg-surface-flower6Hover\/60 {
  background-color: #ff149399;
}

.bg-surface-flower6Hover\/65 {
  background-color: #ff1493a6;
}

.bg-surface-flower6Hover\/70 {
  background-color: #ff1493b3;
}

.bg-surface-flower6Hover\/75 {
  background-color: #ff1493bf;
}

.bg-surface-flower6Hover\/80 {
  background-color: #ff1493cc;
}

.bg-surface-flower6Hover\/85 {
  background-color: #ff1493d9;
}

.bg-surface-flower6Hover\/90 {
  background-color: #ff1493e6;
}

.bg-surface-flower6Hover\/95 {
  background-color: #ff1493f2;
}

.bg-surface-flower7 {
  --tw-bg-opacity: 1;
  background-color: rgb(138 43 226 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower7\/0 {
  background-color: #8a2be200;
}

.bg-surface-flower7\/10 {
  background-color: #8a2be21a;
}

.bg-surface-flower7\/100 {
  background-color: #8a2be2;
}

.bg-surface-flower7\/15 {
  background-color: #8a2be226;
}

.bg-surface-flower7\/20 {
  background-color: #8a2be233;
}

.bg-surface-flower7\/25 {
  background-color: #8a2be240;
}

.bg-surface-flower7\/30 {
  background-color: #8a2be24d;
}

.bg-surface-flower7\/35 {
  background-color: #8a2be259;
}

.bg-surface-flower7\/40 {
  background-color: #8a2be266;
}

.bg-surface-flower7\/45 {
  background-color: #8a2be273;
}

.bg-surface-flower7\/5 {
  background-color: #8a2be20d;
}

.bg-surface-flower7\/50 {
  background-color: #8a2be280;
}

.bg-surface-flower7\/55 {
  background-color: #8a2be28c;
}

.bg-surface-flower7\/60 {
  background-color: #8a2be299;
}

.bg-surface-flower7\/65 {
  background-color: #8a2be2a6;
}

.bg-surface-flower7\/70 {
  background-color: #8a2be2b3;
}

.bg-surface-flower7\/75 {
  background-color: #8a2be2bf;
}

.bg-surface-flower7\/80 {
  background-color: #8a2be2cc;
}

.bg-surface-flower7\/85 {
  background-color: #8a2be2d9;
}

.bg-surface-flower7\/90 {
  background-color: #8a2be2e6;
}

.bg-surface-flower7\/95 {
  background-color: #8a2be2f2;
}

.bg-surface-flower7Active {
  --tw-bg-opacity: 1;
  background-color: rgb(75 0 130 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower7Active\/0 {
  background-color: #4b008200;
}

.bg-surface-flower7Active\/10 {
  background-color: #4b00821a;
}

.bg-surface-flower7Active\/100 {
  background-color: indigo;
}

.bg-surface-flower7Active\/15 {
  background-color: #4b008226;
}

.bg-surface-flower7Active\/20 {
  background-color: #4b008233;
}

.bg-surface-flower7Active\/25 {
  background-color: #4b008240;
}

.bg-surface-flower7Active\/30 {
  background-color: #4b00824d;
}

.bg-surface-flower7Active\/35 {
  background-color: #4b008259;
}

.bg-surface-flower7Active\/40 {
  background-color: #4b008266;
}

.bg-surface-flower7Active\/45 {
  background-color: #4b008273;
}

.bg-surface-flower7Active\/5 {
  background-color: #4b00820d;
}

.bg-surface-flower7Active\/50 {
  background-color: #4b008280;
}

.bg-surface-flower7Active\/55 {
  background-color: #4b00828c;
}

.bg-surface-flower7Active\/60 {
  background-color: #4b008299;
}

.bg-surface-flower7Active\/65 {
  background-color: #4b0082a6;
}

.bg-surface-flower7Active\/70 {
  background-color: #4b0082b3;
}

.bg-surface-flower7Active\/75 {
  background-color: #4b0082bf;
}

.bg-surface-flower7Active\/80 {
  background-color: #4b0082cc;
}

.bg-surface-flower7Active\/85 {
  background-color: #4b0082d9;
}

.bg-surface-flower7Active\/90 {
  background-color: #4b0082e6;
}

.bg-surface-flower7Active\/95 {
  background-color: #4b0082f2;
}

.bg-surface-flower7Hover {
  --tw-bg-opacity: 1;
  background-color: rgb(148 0 211 / var(--tw-bg-opacity, 1));
}

.bg-surface-flower7Hover\/0 {
  background-color: #9400d300;
}

.bg-surface-flower7Hover\/10 {
  background-color: #9400d31a;
}

.bg-surface-flower7Hover\/100 {
  background-color: #9400d3;
}

.bg-surface-flower7Hover\/15 {
  background-color: #9400d326;
}

.bg-surface-flower7Hover\/20 {
  background-color: #9400d333;
}

.bg-surface-flower7Hover\/25 {
  background-color: #9400d340;
}

.bg-surface-flower7Hover\/30 {
  background-color: #9400d34d;
}

.bg-surface-flower7Hover\/35 {
  background-color: #9400d359;
}

.bg-surface-flower7Hover\/40 {
  background-color: #9400d366;
}

.bg-surface-flower7Hover\/45 {
  background-color: #9400d373;
}

.bg-surface-flower7Hover\/5 {
  background-color: #9400d30d;
}

.bg-surface-flower7Hover\/50 {
  background-color: #9400d380;
}

.bg-surface-flower7Hover\/55 {
  background-color: #9400d38c;
}

.bg-surface-flower7Hover\/60 {
  background-color: #9400d399;
}

.bg-surface-flower7Hover\/65 {
  background-color: #9400d3a6;
}

.bg-surface-flower7Hover\/70 {
  background-color: #9400d3b3;
}

.bg-surface-flower7Hover\/75 {
  background-color: #9400d3bf;
}

.bg-surface-flower7Hover\/80 {
  background-color: #9400d3cc;
}

.bg-surface-flower7Hover\/85 {
  background-color: #9400d3d9;
}

.bg-surface-flower7Hover\/90 {
  background-color: #9400d3e6;
}

.bg-surface-flower7Hover\/95 {
  background-color: #9400d3f2;
}

.bg-surface-space {
  --tw-bg-opacity: 1;
  background-color: rgb(6 39 38 / var(--tw-bg-opacity, 1));
}

.bg-surface-tree1 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 93 28 / var(--tw-bg-opacity, 1));
}

.bg-surface-tree2 {
  --tw-bg-opacity: 1;
  background-color: rgb(44 110 73 / var(--tw-bg-opacity, 1));
}

.bg-surface-tree3 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 78 65 / var(--tw-bg-opacity, 1));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

.bg-opacity-20 {
  --tw-bg-opacity: .2;
}

.bg-opacity-30 {
  --tw-bg-opacity: .3;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-60 {
  --tw-bg-opacity: .6;
}

.bg-opacity-70 {
  --tw-bg-opacity: .7;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pe-1 {
  padding-inline-end: .25rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.leading-6 {
  line-height: 1.5rem;
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity, 1));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity, 1));
}

.text-foreground-creamWhite {
  --tw-text-opacity: 1;
  color: rgb(241 248 233 / var(--tw-text-opacity, 1));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity, 1));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity, 1));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity, 1));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity, 1));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity, 1));
}

.text-sf-slate {
  --tw-text-opacity: 1;
  color: rgb(47 79 79 / var(--tw-text-opacity, 1));
}

.text-sf-text-invert {
  --tw-text-opacity: 1;
  color: rgb(33 33 33 / var(--tw-text-opacity, 1));
}

.text-slate-50 {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity, 1));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-90 {
  opacity: .9;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-glow {
  --tw-shadow: 0 0 8px 2px #00ff7f80;
  --tw-shadow-colored: 0 0 8px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-shadow-deepCharcoal {
  --tw-shadow-color: #333;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-white {
  --tw-shadow-color: #fff;
  --tw-shadow: var(--tw-shadow-colored);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black\/5 {
  --tw-ring-color: #0000000d;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

body {
  font-family: Nunito, sans-serif;
}

.particle-container {
  pointer-events: none;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.particle {
  animation-name: floatUp;
  position: absolute;
  bottom: -10%;
}

.stableScrollGutters {
  scrollbar-width: none;
}

@keyframes pulse2 {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse2;
}

@keyframes floatUp {
  to {
    transform: translateY(-120vh);
  }
}

.snuggle-tag-stage {
  background-color: #2e7d32;
}

.snuggle-tag-ai {
  background-color: #ff8f00;
}

.snuggle-tag-age {
  background-color: #0d47a1;
}

.snuggle-tag-cost {
  background-color: #c62828;
}

.bg-forest-overlay {
  z-index: 0;
  position: relative;
}

.bg-forest-overlay:before {
  content: "";
  opacity: .2;
  z-index: -1;
  background-image: url("forest-bg.7eb84b88.webp");
  background-repeat: repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity, 1));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity, 1));
}

.hover\:bg-sf-slate:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(47 79 79 / var(--tw-bg-opacity, 1));
}

.hover\:bg-surface-flower3Hover:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(50 153 51 / var(--tw-bg-opacity, 1));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity, 1));
}

.focus\:ring-white\/75:focus {
  --tw-ring-color: #ffffffbf;
}

.focus\:ring-opacity-50:focus {
  --tw-ring-opacity: .5;
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-blue-500:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity, 1));
}

.focus-visible\:ring-white\/75:focus-visible {
  --tw-ring-color: #ffffffbf;
}

.focus-visible\:ring-offset-2:focus-visible {
  --tw-ring-offset-width: 2px;
}

.active\:bg-surface-flower3Active:active {
  --tw-bg-opacity: 1;
  background-color: rgb(0 77 0 / var(--tw-bg-opacity, 1));
}

@media (width >= 640px) {
  .sm\:mr-2 {
    margin-right: .5rem;
  }

  .sm\:mr-4 {
    margin-right: 1rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-40 {
    height: 10rem;
  }

  .sm\:h-44 {
    height: 11rem;
  }

  .sm\:w-12 {
    width: 3rem;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:gap-12 {
    gap: 3rem;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:pb-8 {
    padding-bottom: 2rem;
  }

  .sm\:pe-2 {
    padding-inline-end: .5rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 768px) {
  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (width >= 1024px) {
  .lg\:mr-6 {
    margin-right: 1.5rem;
  }
}
/*# sourceMappingURL=index.93a4120c.css.map */
